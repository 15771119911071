/* General App styling */
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../public/landing-main.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh; /* Ensure the minimum height takes the full screen */
  height: 100%; /* Ensure height is full to avoid any overlapping */
  overflow-y: auto; /* Ensure scrolling if the content overflows */
  margin: 0;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in element size */
}

/* Card container styles */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  max-width: 1200px;
  margin-top: 20px;
  padding-bottom: 40px;
  box-sizing: border-box; /* Prevent padding from affecting layout */
}

/* Filter button styles */
.filter-buttons {
  margin-bottom: 20px;
}

.filter-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #1e4219; /* Match button color */
  color: white;
  cursor: pointer;
}

/* Card styles */
.card {
  background-color: white;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
  margin: 10px;
  padding: 20px;
  width: 300px;
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Card title */
.card-title {
  font-weight: bold;
  color: #333; /* Title color */
}

/* Card tag styles */
.card-tag {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 5px 10px;
  color: #d35400; /* Tag color */
  font-weight: bold;
}

/* Media queries for responsiveness */

/* For smaller screens */
@media (max-width: 768px) {
  .app {
    padding: 10px; /* Add padding on small screens */
  }

  .card {
    width: 100%; /* Ensure cards take full width */
    margin: 10px 0; /* Adjust card margins for mobile */
  }

  .filter-buttons {
    display: flex;
    gap: 10px;
    align-items: center; /* Center buttons */
    margin-bottom: 20px;
  }

  .filter-buttons button {
    width: 100%; /* Make buttons full-width on small screens */
    margin: 10px 0; /* Add spacing between buttons */
  }

  .card-container {
    margin-top: 20px;
    padding-bottom: 20px;
  }
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 40px; /* Add some top padding to avoid overlap */
  margin-bottom: 20px; /* Margin below header content */
}


/* For extra small screens (e.g., very narrow devices) */
@media (max-width: 480px) {
  .app {
    padding: 10px 5px; /* Adjust padding for very small screens */
  }

  .filter-buttons button {
    font-size: 14px; /* Reduce font size for buttons */
  }
}
