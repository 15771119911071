.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 16px;
  width: 250px;
  cursor: pointer;
  font-family: Arial, sans-serif;
}


.card-header {
  display: flex;
  align-items: center; 
  gap: 12px;
  justify-content: space-between
}


.card-image {
  width: 40px; 
  height: 40px;
  border-radius: 50%;
}


.card-title-container {
  display: flex;
  flex-direction: row; 
  justify-content: center; 
  gap: 4px;
}


.card-title-container h3 {
  margin: 0;
}


.card-type {
  background-color: #e0f7e9;
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 12px;
  color: #388e3c; 
  display: flex;
  justify-content: center;
  align-items: center;
}


.card p {
  margin: 0;
  font-size: 14px;
  color: #333;
  margin-top: 8px;
}


@media (min-width: 768px) {
  .card-header {
    gap: 16px; 
  }

  .card-title-container {
    width: 100%;
    justify-content: space-evenly;

    align-items: center; 
  }

  .card-type {
    margin-top: 0;
  }
}
