/* src/Header.css */
.header {
    text-align: center;
    padding: 20px;
    background-color: #f8f8f8;
    background-color: transparent; /* Ensure section is transparent */

  }
  
  .header h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    background-color: transparent; /* Ensure section is transparent */
    color: white; /* Set text color to white */

  }
  
  .header p {
    font-size: 1.2em;
    margin-bottom: 20px;
    background-color: transparent; /* Ensure section is transparent */
  }
  
  .contact-button {
    background-color: #ff6600;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 1em;
  }
  
  .icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .icons img {
    width: 50px;
    height: 50px;
  }
